rodijco.controller 'DashboardController', [
  '$scope'
  'httpService'
  (scope, httpService) ->

    # Logout
    scope.logout = ->
      httpService.get 'user/logout', (response) ->
        return 'error' if not response.success
        window.location = '/'

    scope.setActive = (index = 0) ->
      $('nav.top-bar section.top-bar-section ul li').eq(index).addClass('active')

]
